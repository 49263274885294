@import '~@primer/css/index.scss';

/* Color */
textarea {
  width: 100%;
  height: 250px;
  resize: none;
  font-family: monospace;
}

.noscript {
  text-align: center;
  font-style: italic;
  margin-bottom: 30px
}

.operations {
  text-align: center
}

.operations>button {
  margin: 5px 10px;
  width: 125px
}

.inputs {
  display: flex;
}

.inputs dd {
  width: 50%;
}

.inputs dd:first-child {
  margin-right: 6px
}

.inputs dd:last-child {
  margin-left: 6px
}

.item-count {
  text-align: right;
  color: #888
}

.format-group {
  text-align: center
}

.format-group label {
  display: inline-block;
  cursor: pointer
}

.format-group .output-item {
  text-align: left;
  width: 152px
}

@media(max-width: 500px) {
  .operations>button {
    width: 100%;
    margin: 5px 0
  }

  .format-group {
    margin: 0 25px 10px 50px
  }

  .format-group label {
    display: block
  }
}

.textbox-wrapper {
  position: relative
}

.textbox-wrapper .textbox-actions {
  position: absolute;
  right: 12px;
  bottom: 12px;
}

.textbox-wrapper .textbox-actions>button {
  display: block;
  width: 32px;
  height: 32px;
  cursor: pointer;
  opacity: 0;
  border: 1px solid #CCC;
  border-radius: 2px;
  background-color: white;
}

.textbox-wrapper:hover .textbox-actions>button {
  opacity: .2
}

.textbox-wrapper .textbox-actions:hover>button,
.textbox-wrapper .textbox-actions>button:focus {
  opacity: .7;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, .1)
}

.octicon {
  text-align: center;
  padding-top: 4px
}

.octicon img {
  height: 20px;
  width: 20px
}

/* Notifications */
.notification-container {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 0 5px 20px;
  opacity: .85;
}

.notification-container .notification {
  max-width: 350px;
  width: 100%;
  margin: 0 auto 5px;
  padding: 10px;
  text-align: center;
  border-radius: 6px;
  font-weight: bold;
  box-shadow: 0 0 1px rgba(0, 0, 0, .4);
}

.notification-container .notification.notification-success {
  background-color: #28a745;
  color: white;
}

.notification-container .notification.notification-warning {
  background-color: #f1e05a;
}

.notification-container .notification.notification-error {
  background-color: #b33630;
  color: white;
}